@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.auslandContainer {
      top: 65px;
      margin-bottom: 65px;
      width: 100%;
      padding-bottom: 80px;
      padding-top: 45px;
      background-color: #fefef5;
}

.auslandNightContainer {
      top: 65px;
      margin-bottom: 65;
      width: 100%;
      padding-bottom: 90px;
      padding-top: 45px;
      background-color: black;
}

.auslandTitle {
      font-weight: 400;
      font-size: 30px;
      display: block;
      margin: 0 auto;
      text-align: center;
}

.auslandNightTitle {
      font-weight: 400;
      font-size: 30px;
      display: block;
      margin: 0 auto;
      text-align: center;
      color: white;
}

@media (max-width: 500px) {
      .auslandNightTitle {
            font-size: 20px;
      }

      .auslandTitle {
            font-size: 20px;
      }
}

.auslandRow {
      margin-top: 40px;
}


/* .auslandCol {
      margin: 0 5px;
} */


.slide {
      transform: scale(0.2);
      transition: transform 300ms;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      left: 38%;
}

.activeSlide {
      margin: 0 auto;
      transform: scale(1.1);
      opacity: 1;
      display: flex;
}

.imageContainer {
      margin: 0 auto;
      width: 100%;
      position: relative;
      z-index: 2
}

.imageContainer:before {
      content: '';
      display: block;
      width: 50%;
      padding-top: 110%;
}

.auslandImage {
      border-radius: 60px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
}

.imageContainer .auslandImage {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
}

.arrow {
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      cursor: pointer;
      z-index: 10;
      font-size: 35px;
      border-radius: 5px;
}

.arrowNight {
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      cursor: pointer;
      z-index: 10;
      font-size: 35px;
      border-radius: 5px;
      color: white;
}

.arrow svg {
      transition: color 300ms;
}

.arrow svg:hover {
      color: #68edff;
}

.next {
      right: 0%;
      top: 50%;
}

.prev {
      left: 0%;
      top: 50%;
}


.Para {
      color: black;
}

.NightPara {
      color: white;
}

.explanatory-text {
      display: flex;
      align-items: center;
      height: 100%;
}

.explanatory-text p {
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px;
      ;
}

.explanatory-text.activeText {
      opacity: 1;
      transition: opacity 300ms;
}



@media (max-width: 450px) {
      .Para {
            font-size: 5px;
      }

      .explanatory-text p {
            font-size: 5px;
      }

      .arrowNight {
            font-size: 20px;
      }
}