* {
      box-sizing: border-box;
      position: relative;
      margin: 0;
      padding: 0;
}

.SignPartContainer {
      width: 100%;
      top: 65px;
      padding-top: 35px;
      padding-bottom: 50px;
      animation: slideLeft 0.5s ease-out forwards;
}


.SignPartNightContainer {
      background-color: #333;
      width: 100%;
      top: 65px;
      padding-top: 35px;
      padding-bottom: 50px;
      animation: slideLeft 0.5s ease-out forwards;
}

@keyframes slideLeft {
      0% {
            transform: translateX(300px);
            opacity: 0;
      }

      25% {
            transform: translateX(150px);
            opacity: 0.3;
      }

      50% {
            transform: translateX(50px);
            opacity: 0.7;
      }

      50% {
            transform: translateX(0px);
            opacity: 1;
      }
}




/* .SignPartRow {
      border: 2px solid blue;
} */

/* .SignPartCol {
      border: 2px solid orange
} */


.SignUpPartTitle {
      display: block;
      margin: 0 auto;
      text-align: center;
}

.AnimatedText {
      animation: colorChange 2s infinite;
}

@keyframes colorChange {
      0% {
            color: black;
      }

      50% {
            color: red;
      }

      100% {
            color: black;
      }
}


.SignUpPartNightTitle {
      display: block;
      margin: 0 auto;
      text-align: center;
      color: white;
}

.SignUpPartTitle:hover,
.SignUpPartNightTitle:hover {
      padding: 20px 16px;
}

.SignUpPartMethods {
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding-bottom: 50px;
}

.SignUpEmailSection {
      width: 400px;
}

.label {
      margin: 1rem 0;
}

.labelAnimation {
      position: relative;

}

.labelAnimation::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 2px;
      background-color: red;
      animation: changeLabel 3s infinite;
}



@keyframes changeLabel {
      0% {
            width: 0;
      }

      20% {
            width: 20%;
      }

      100% {
            width: 100%;
      }
}

.Nightlabel {
      margin: 1rem 0;
      color: white;
}

.Input {
      width: 100%;
      border: 2px solid #ced4da;
      border-radius: 4px;
      padding: 8px 12px;
      transition: border-color 0.3s ease;
}

.Input:hover {
      border-color: pink;
}

.Submit {
      border-radius: 5px;
      width: 100%;
      height: 50px;
      margin-top: 1rem;
      background-color: #10a37f;
}

.Submit:hover {
      animation: shake 0.5s;
      animation-iteration-count: infinite;
}

.SignUpThridWay {
      width: 400px;
      display: flex;
      flex-direction: column;
      padding-top: 25px;
}

.ThirdWayTitle {
      display: block;
      margin: 0 auto;
      text-align: center;
}

.ThirdWayNightTitle {
      display: block;
      margin: 0 auto;
      text-align: center;
      color: white;
}

.GoogleContainer {
      display: flex;
      width: 100%;
      cursor: pointer;
      outline: 0;
      background-color: #ffffff;
      height: 50px;
      margin: 1rem 0;
      padding-top: 4px;
}

.GoogleContainer:hover {
      animation: shake 0.5s;
      animation-iteration-count: infinite;
}

@keyframes shake {
      0% {
            transform: translate(1px, 1px) rotate(0deg);
      }

      10% {
            transform: translate(-1px, -2px) rotate(-1deg);
      }

      20% {
            transform: translate(-3px, 0px) rotate(1deg);
      }

      30% {
            transform: translate(3px, 2px) rotate(0deg);
      }

      40% {
            transform: translate(1px, -1px) rotate(1deg);
      }

      50% {
            transform: translate(-1px, 2px) rotate(-1deg);
      }

      60% {
            transform: translate(-3px, 1px) rotate(0deg);
      }

      70% {
            transform: translate(3px, 1px) rotate(-1deg);
      }

      80% {
            transform: translate(-1px, -1px) rotate(1deg);
      }

      90% {
            transform: translate(1px, 2px) rotate(0deg);
      }

      100% {
            transform: translate(1px, -2px) rotate(-1deg);
      }
}

.GoogleIcon {
      width: 35px;
      height: 35px;
      display: block;
      background-image: url(../Image/GoogleIcon.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transform: translateX(180%);
}

.GooglePara {
      font-size: 20px;
      transform: translateX(50%);
}

.FaceBookContainer {
      display: flex;
      width: 100%;
      cursor: pointer;
      outline: 0;
      background-color: #ffffff;
      height: 40px;
      margin: 1rem 0;
}

.FaceBookIcon {
      width: 35px;
      height: 35px;
      display: block;
      background-image: url(../Image/Facebook_icon.svg.webp);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transform: translateX(180%);
}

.GithubIcon {
      width: 35px;
      height: 35px;
      display: block;
      background-image: url(../Image/Git2.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transform: translateX(180%);
      border-radius: 4px;
}


.FacebookPara {
      font-size: 20px;
      transform: translateX(50%);
}

.AlreadyAccount {
      display: block;
      margin: 0 auto;
      top: 1rem;
}

.AlreadyNightAccount {
      display: block;
      margin: 0 auto;
      top: 1rem;
      color: white;
}

.LogoutContainer {
      text-align: center;
      margin-top: 20px;
}

.BtnContainer {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 10px;
}

.photo {
      width: 70px;
      height: 70px
}

.LogOutBtn {
      background-color: #10a37f;
      height: 30px;
      width: 70px;
      color: white;
      border: none;
      display: block;
}

.StartBtn {
      background-color: #10a37f;
      height: 30px;
      width: 70px;
      color: white;
      border: none;
      display: block;
}

@media (max-width: 900px) {
      .SignUpEmailSection {
            margin: 0 1rem;
      }
}

@media (max-width: 796px) {
      .GooglePara {
            font-size: 10px;
            transform: translateX(5%);
      }

      .FacebookPara {
            font-size: 10px;
            transform: translateX(5%);
      }

      .GithubIcon {
            width: 15px;
            height: 15px;
            transform: translateX(10%);
      }

      .GoogleIcon {
            width: 15px;
            height: 15px;
            transform: translateX(10%);
      }

      .AlreadyAccount,
      .AlreadyNightAccount {
            top: 0;
            font-size: 10px;
      }
}

.Alert {
      font-size: 20px;
      text-align: center;
      margin: 0.5rem 0;
      color: red;
      font-weight: 700;
}