* {
      padding: 0;
      margin: 0;
      position: relative;
      box-sizing: border-box;
}



.UbungContainer {
      z-index: 6;
      width: 100%;
      padding: 96px 0 48px 0;
      top: 66px;
      margin-bottom: 66px;
      background-image: url(../Image/UbungIM1.svg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      animation-name: moveContainer;
      animation-duration: 1s;
      animation-fill-mode: forwards;
}

.UbungNightContainer {
      top: 66px;
      z-index: 6;
      width: 100%;
      padding: 96px 0 48px 0;
      background-color: rgba(0, 0, 0, 0.8);
      margin-bottom: 66px;
      animation-name: moveContainer;
      animation-duration: 1s;
      animation-fill-mode: forwards;
}

@keyframes moveContainer {
      from {
            padding-top: 0;
      }

      to {
            padding-top: 96px;
      }
}

.UbungContainer .portoContainer {
      max-width: 540px;
      margin-bottom: 60px;
}

.UbungNightContainer .portoContainer {
      max-width: 540px;
      margin-bottom: 60px;
}


/* 有關主要的部份 */


.portoContainer {
      max-width: 540px;
      margin-bottom: 60px;
}





.portoTitle {
      width: 100%;
      color: black;
      text-align: center;
}

.portoNightTitle {
      width: 100%;
      color: white;
      text-align: center;
      margin-bottom: 24px;
}

.section-title {
      font-size: 2rem;
      line-height: 1.5;
      font-weight: 500;
      text-align: center;
}