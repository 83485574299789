* {
      position: relative;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
}


a {
      text-decoration: none;
}

.footerContainer {
      background-color: #fff;
      min-height: 160px;
      border: 1px solid rgba(0, 0, 0, 0.4);
      z-index: 2;
      width: 100%;
      margin-top: 10px;

}

.blackMode {
      background-color: black;
      min-height: 160px;
      border: 1px solid rgba(0, 0, 0, 0.4);
      z-index: 2;
      max-width: 100%;
      margin-top: 10px;
}


.contentContainer {

      margin: 0 auto;
      display: flex;
      flex-direction: column;
}

.contentList {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 20px;
      margin-top: 30px;
}

.contentItem {
      cursor: pointer;
      margin: 0 15px;
}

@media (max-width: 300px) {
      .contentItem {
            margin: 0;
      }

      .footerIcon {
            width: 20px;
            height: 20px;
            display: block;
      }

      .copyRight {
            margin: 0;
            font-size: 10px;
      }

      .blackMode2 {
            margin: 0;
            font-size: 10px;
      }

      .footerContainer {
            margin: 0;
            /* width: 100%; */
      }
}

.blackMode2 {
      color: white;

}

.copyRight {
      margin: 0 auto;

}